  /* Estilos generales de la sidebar */
  .sidebar {
    width: 16rem;
    padding: 1.5rem 1.5rem 2rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    background-color: var(--white);
    font-family: "ibm plex sans", "Roboto", sans-serif;
    border: 1px solid var(--gray);
    border-radius: 30px;
    transition: width 0.4s ease, padding 0.4s ease;
  }

  .sidebar p {
    margin: 0;
    line-height: 1.15;
  }

  /* Título de la sidebar */
  .sidebar-title {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .sidebar-title p {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--dark);
  }

  .sidebar-buttons {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .step {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    opacity: 1;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
    transition: padding 0.6s ease, height 0.6s ease;
  }

  .step-complete {
    cursor: pointer;
  }

  .step-complete p {
    color: var(--dark);
  }

  .step-complete:hover {
    text-decoration: underline;
    color: var(--primary);
  }

  .current {
    font-weight: 600;
  }

  .index {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    width: 2rem;
    height: 2rem;
  }

  .index-complete {
    background-color: var(--dark);
    color: white;
    cursor: pointer;
  }

  .index-pending {
    background-color: var(--input-gray);
    color: var(--light);
  }

  .index-pending p {
    color: var(--light-gray);
  }

  .step p {
    width: calc(100% - 3rem);
    color: var(--dark);
  }

  /* Estilos para la versión colapsada */
  .sidebar.collapsed {
    width: 100%;
    padding: 1.2rem 1.5rem;
    height: 2.5rem;
    overflow: hidden;
    transition: width 0.4s ease, padding 0.4s ease;
  }

  .sidebar.collapsed .sidebar-title p {
    display: block;
    font-size: 1.1rem;
    transition: width 0.6s ease, padding 0.6s ease;
  }

  .sidebar.collapsed .step {
    opacity: 0;
    transform: scaleY(0);
    height: 0;
    pointer-events: none;
  }

  .col75 table {
    max-width: 95%;
    width: 100%;
    overflow-x: auto;
    display: block; /* Permite que el overflow-x funcione en tablas */
}

  @media (max-width: 765px) {
    .sidebar {
      width: 100%;
      padding: 1.5rem 1.5rem 2rem;
      transition: width 0.4s ease, padding 0.4s ease;
    }
  }
