.digitalDiary {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6rem;
    max-width: 1200px;
    margin: auto;
    padding: 2rem;
    width: calc(100% - 4rem);
    min-height: calc(100vh - 4.5rem - 2rem);
}

.diaryCol25 {
    display: flex;
    width: calc(25%);
    height: 100%;
}

.diaryCol75 {
    display: flex;
    flex-flow: column;
    width: calc(75% - 6rem);
    height: 100%;
}

.digitalDiary h1 {
    font-size: 2rem;
    margin: 0;
}

.digitalDiary h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.diaryParagraph {
    font-size: 1rem;
    margin: 1rem 0 0;
}

.diaryNote {
    font-size: 0.9rem;
    color: var(--primary);
    margin: 1rem 0;
}

.entries {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.diaryTab {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--gray);
    cursor: pointer;
    padding: 1rem 0.5rem;
    width: 100%;
}

.activeDiaryTab {
    color: var(--primary);
    font-weight: 600;
}

.submitButton {
    margin: 1rem 0 0;
}

@media (max-width: 800px) {
    .vocaLayout {
        flex-flow: column;
    }

    .col25, .col75 {
        width: 100%;
    }
}
