/* Estructura general */
.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; 
    font-family: 'ibm plex sans', 'Roboto', sans-serif;
    padding: 1rem;
    min-height: 100vh;
    background-color: #f5f8fa; 
    background-image: url('../../assets/flecha_feb.png'), url('../../assets/flecha_feb.png');
    background-size: 20% auto, 28% auto;
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
}

.link {
    background-color: transparent;
    border: none;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.divider {
    width: 2px;
    height: auto; 
    background-color: #005280; 
    margin: 0 2rem;
    align-self: stretch;
}

/* Sección izquierda */
.left {
    width: 60%; 
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: left;
    margin: auto;
}


.logo {
    display: block; 
    margin: 0 auto;
    margin-bottom: 1rem;
}

.container h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0a4a75;
    margin-bottom: 1rem;
    text-align: left; 
}
    
.container p {
    color: #0a4a75;    
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.subtitle {
    text-align: left;
    color: #0a4a75;
    font-size: 0.45rem;
    margin-top: 1rem; 
    line-height: 1.2; 
    width: 100%;
    opacity: 0.8;
}

.footerdescription {
    text-align: center;
    color: #0a4a75;
    font-size: 0.96rem;
    margin-top: 3rem; 
    line-height: 1.2; 
    width: 100%;
    opacity: 0.8;
}

.container button {
    background-color: #0a4a75;
    color: white;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
}

.container button:hover {
    background-color: #083655; /* Efecto hover */
}

.right {
    flex: 1;
    max-width: 500px; /* Limita el ancho máximo */
    background-color: #ffffff;
    margin-top: 2rem; /* Evita que esté muy por arriba */
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: left;
}

.right h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #0a4a75;
    margin-bottom: 1.5rem;
    text-align: left; /* Asegura que el título esté alineado a la izquierda */
}

.right h4 {
    font-size: 1.2rem;
    color: #0a4a75;    
    margin-bottom: 0.5rem;
}

.row {
    font-size: 1rem;
    color: #0a4a75;
    margin-bottom: 1rem;
}

.right .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: #0a4a75;
}

.container h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #0a4a75;
    margin-bottom: 1.5rem;
}

.container h4 {
    font-size: 1.2rem;
    color: #0a4a75;
    margin-bottom: 0.5rem;
}

.row {
    font-size: 1rem;
    color: #0a4a75;
    margin-bottom: 1rem;
}

.paymentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.logo {
    margin-bottom: 1rem;
}

.pivoteawelcome {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0a4a75;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.pivoteaCenteredWelcome {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0a4a75;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 1rem auto;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    font-size: 1.1rem;
    color: #4a4a4a;
    margin-bottom: 2rem;
    line-height: 1.5;
}

.descriptionCenter {
    font-size: 1.1rem;
    color: #4a4a4a;
    margin-bottom: 2rem;
    line-height: 1.5;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.platformGrid {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    max-width: 100%;
    text-align: left; 
}

.platform {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
}

.platform h4 {
    font-size: 1.2rem;
    color: #0a4a75;
    margin-bottom: 0.5rem;
}

ol {
    padding-left: 1rem;
}


/* Responsividad */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 1rem;
    }

    .platform.left, .platform.right {
        max-width: 100%;
    }

    .left, .right {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .right {
        max-width: none; 
        margin-top: 0rem;
        padding: 1rem;
    }

    .logo {
        width: 100px;
    }

    .container h1 {
        font-size: 2rem;
    }

    .container p, .container h4, .row {
        font-size: 1rem;
         color: #0a4a75; 
    }

    .container button {
        width: 100%;
        padding: 0.8rem;
    }
}
