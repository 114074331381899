/* Estilos generales para el contenedor Markdown */
.markdownContainer {
    font-family: system-ui, sans-serif;
    line-height: 1.6;
    padding: 16px;
    color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 8px;
    font-size: 0.875rem;
    max-width: 70%;
    background-color: #f9f9f9; /* bg-light-og */
    color: #1a1a1a; /* text-dark-og-1 */
    margin: 0;
    align-self: flex-start;
  }
  
  /* Estilos para encabezados */
  .markdownContainer h1 {
    font-size: 1.75em;
    font-weight: bold;
    line-height: 1.25;
    margin: 24px 0;
  }
  
  .markdownContainer h2 {
    font-size: 1.55em;
    font-weight: bold;
    line-height: 1.25;
    margin: 20px 0;
  }
  
  .markdownContainer h3 {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.25;
    margin: 18px 0;
  }
  
  .markdownContainer h4 {
    font-size: 1.1em;
    font-weight: bold;
    line-height: 1.25;
    margin: 16px 0;
  }
  
  .markdownContainer h5 {
    font-size: 1em;
    font-weight: bold;
    line-height: 1.25;
    margin: 14px 0;
  }
  
  .markdownContainer h6 {
    font-size: 0.875em;
    font-weight: bold;
    line-height: 1.25;
    margin: 12px 0;
  }
  
  /* Estilos para párrafos */
  .markdownContainer p {
    margin: 6px 0;
    font-size: 0.875rem;
  }
  
  /* Estilos para listas */
  .markdownContainer ul {
    list-style-type: disc;
    padding-left: 40px;
    margin: 16px 0;
  }
  
  .markdownContainer ol {
    list-style-type: decimal;
    padding-left: 40px;
    margin: 16px 0;
  }
  
  .markdownContainer li {
    margin: 8px 0;
  }
  
  /* Estilos para texto en negrita */
  .markdownContainer strong {
    font-weight: bold;
  }
  
  /* Estilos para citas */
  .markdownContainer blockquote {
    border-left: 4px solid #ccc;
    padding-left: 16px;
    color: #666;
    margin: 16px 0;
    font-style: italic;
  }
  
  /* Estilos para bloques de código */
  .markdownContainer pre {
    background-color: #f5f5f5;
    padding: 16px;
    overflow-x: auto;
    border-radius: 4px;
    margin: 16px 0;
  }
  
  .markdownContainer code {
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: "Courier New", Courier, monospace;
    font-size: 0.95em;
  }
  
  /* Estilos para tablas */
  .markdownContainer table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
    margin: 0 auto;
  }
  
  .markdownContainer tbody,
  .markdownContainer td,
  .markdownContainer tfoot,
  .markdownContainer th,
  .markdownContainer thead,
  .markdownContainer tr {
    border: 2px solid inherit;
    padding: 0.5rem;
  }
  