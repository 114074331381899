.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    background-color: white;
    color: black;
    cursor: pointer;
}