.container {
    padding: 1em;
    text-align: left;
    max-width: 1200px; 
    margin: 0 auto;
  }

  .titlesection {
    display: flex; 
    justify-content: space-between; 
    gap: 1em; 
    margin-bottom: 1.5em; 
    flex-direction: row;
    align-items: end;
}


  .titles{
    padding: 1em 1em 0 0;
    text-align: left;
    align-items: end;
  }

  .titles h2 {
    font-weight: 400;
    margin-bottom: 0.5em;
    font-size: 18px;
  }

  .titles h1 {
    font-weight: 600;
    margin-top: 0; 
    font-size: 32px;
  }
  
  .filter {
    display: flex;
    align-items: center; 
    justify-content: flex-end;
    padding-bottom: 24px;
}

.checkboxLabel {
    display: flex;
    align-items: center; 
    gap: 8px; 
    font-size: 16px;
    cursor: pointer; 
}

.checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 6px;
}

  
  .modules {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 3em;
  }

  .moduleCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    cursor: pointer;
  }

    .moduleCard:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        transform: scale(1.02);
    }
  
  .moduleImage {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .moduleInfo {
    padding: 1em;
  }
  
  .progressBar {
    background-color: #eee;
    border-radius: 4px;
    width: 100%;
    height: 8px;
    margin-top: 0.5em;
  }
  
  .progress {
    background-color: #004DFF;
    height: 100%;
    border-radius: 4px;
  }

  .modules p {
    color: #75767a;
  }

  .spinnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}

.spinner {
    border: 8px solid #f3f3f3; 
    border-top: 8px solid #3b82f6; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 1em; 
}

.lockedMessage {
  color: red;
  font-size: 0.9em;
  margin-top: 8px;
  text-align: center;
}

.moduleCard.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

@media (max-width: 765px) {
  .spinnerContainer {
    width: 90vw;
  }

  .titles {
    text-align: center;
  }

  .titlesection{
    flex-direction: column;
    align-items: center;
  }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


