@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700&family=Inter:ital,opsz,wght@0,14..32,100..900&display=swap');

.landing {
  font-family: 'Poppins', 'Roboto', sans-serif;
  overflow-x: hidden;
}

.containerSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #e4f6ea;
  align-items: center;
} 

.containerText {
  width: 50%;
  height: 100%;
  padding: 80px 40px;
}

.containerImage {
  width: 35%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleSection {
  color: #005280;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Inter';
  text-transform: uppercase;
  letter-spacing: 0.4;
  word-wrap: break-word;
}

.subtitleSection {
  color: #005280;
  font-size: 16px;
  font-family: 'Chakra Petch';
  font-weight: 400;
  letter-spacing: 0.16;
  margin-top: 24px;
  line-height: 1.5;
  word-break: normal;
  overflow-wrap: break-word;
}

.titleTestimoniosSection{
  color: #005280;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
}

.buyButton {
  background-color: #005280;
  color: white;
  text-transform: uppercase;
  font-family: 'Chakra Petch';
  font-size: 16px;
  padding: 16px 24px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: none;
  margin-top: 24px;
}

.buyButton:hover {
  cursor: pointer;
}

.programDescriptionSection {
  width: 100%;
  height: 656px;
  background-color: #005280;
  display: inline-flex;
  flex-direction: column;
}

.testimoniosSection {
  width: 100%;
  background-color: #a0dbdc;
  text-align: center;
  padding: 80px 0;
}

.programContentSection {
  width: 100%;
  height: auto;
  background-color: white;
}

.teamCoachesSection {
  background: #E4F6EA;
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.bannerSection {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/banner_landing_page.png');
}

.faqSection {
  width: 100%;
  padding: 80px 0px;
  position: relative;
  background: url('../../assets/back-faq.png') 50% / contain no-repeat, #3F7DA0;
}

.faqContainer {
  max-width: 80%;
  margin: 0 auto;
}

.itemFaq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-bottom: 24px;
}

.titleFaq {
  width: 100%;
  color: #E4F6EA;
  background-color: #005280;
}

.titleFaq .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.18px;
  padding: 16px;
  background-color: transparent;
  border: none;
}

.collapsablFaq {
  width: 100%;
  color: #005280;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: #a0dbdc;
}

.collapsablFaq h4 {
  color: #005280;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.16px;
  padding: 16px 24px;
}

.itemsSection {
  display: inline-flex;
  height: 100%;
  margin-right: 70px;
  margin-left: 70px;
  margin-bottom: 80px;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.titleDescriptionSection {
  color: #e4f6ea;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
}

.subtitleDescriptionSection {
  color: #e4f6ea;
  text-align: center;
  font-family: 'Chakra Petch';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.item {
  width: 33.3%;
  height: 100%;
  background-color: #3f7da0;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  margin-right: 24px;
}

.cardTitle {
  color: #e4f6ea;
  font-size: 18px;
  text-align: center;
  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0.18px;
  word-wrap: break-word;
}

.cardText {
  text-align: center;
  color: #e4f6ea;
  font-size: 16px;
  font-family: 'Chakra Petch';
  font-weight: 400;
  letter-spacing: 0.16px;
  word-wrap: break-word;
  margin-top: 16px;
}

.programContentTitle {
  text-align: center;
  color: #005280;
  font-size: 40px;
  font-family: Inter;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.programCard {
  background-color: #a0dbdc;
  margin-bottom: 24px;
  margin-left: 235px;
  margin-right: 234px;
  padding: 16px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.programCardLast {
  background-color: #a0dbdc;
  margin-bottom: 24px;
  margin-left: 235px;
  margin-right: 234px;
  padding: 16px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: 100px;
}

.programTitle {
  color: #005280;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0.18px;
}

.programDescriptionSection {
  width: 100%;
  height: 656px;
  background-color: #005280;
  display: inline-flex;
  flex-direction: column;
}

.items-section {
  flex-direction: column;
  margin: 40px 24px 80px 24px;
}

.programContentTitle {
  text-align: center;
  color: #005280;
  font-size: 40px;
  font-family: 'Inter';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 80px;
  margin-bottom: 40px;
}


.subtitleDescriptionSection {
  text-align: center; 
  display: flex;     
  justify-content: center; 
  align-items: center; 
  color: #e4f6ea;
  font-family: 'Chakra Petch';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0 auto; 
  width: 100%; 
  max-width: 768px; 
}

.programText {
  color: #005280;
  font-size: 16px;
  font-family: 'Chakra Petch';
  font-weight: 400;
  letter-spacing: 0.16px;
  margin-top: 16px;
}

.teamCoachesTitle {
  color: #005280;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.teamCoachesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  width: auto;
  align-items: flex-start;
  gap: 30px;
}

.teamCoachesGridItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.teamCoachesGridItem:hover {
  background-color: #005280;
  cursor: pointer;
}

.teamCoachesGridItem:hover .title {
  color: #E4F6EA;
}

.teamCoachesGridItem:hover .paragraph {
  color: #E4F6EA;
}

.gridItemContainer {
  display: flex;
  width: 300px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.teamCoachesGridItem .title {
  color: #005280;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.18px;
}

.teamCoachesGridItem .paragraph {
  color: #005280;
  font-family: "Chakra Petch";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.banner {
  position: absolute;
  top: 1;
  left: 1;
  width: calc(100% - 200px);
  height: auto;
  background-color: rgba(160, 219, 220, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
}

.bannerSectionTitle {
  text-align: center;
  color: #005280;
  font-size: 40px;
  font-family: 'Inter';
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 40px;
}

.bannerSectionSubtitle {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: 'Chakra Petch';
  font-weight: 600;
  letter-spacing: 0.16px;
  color: #005280;
  margin-top: 24px;
}

.bannerSectionButton {
  background: #005280;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: auto;
  border: none;
  color: #fbfbfb;
  font-size: 16px;
  font-family: 'Chakra Petch';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.16px;
  padding: 24px;
  margin-bottom: 40px;
  margin-top: 24px;
}

.bannerSectionButton:hover {
  cursor: pointer;
}

.faqTitle {
  text-align: center;
  color: #e4f6ea;
  font-size: 40px;
  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0.40px;
  margin-bottom: 40px;
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .containerText {
    margin: 24px;
  }

  .titleSection {
    font-size: 36px;
  }

  .subtitleSection {
    width: 100%;
  }

  .programCard,
  .programCardLast {
    margin: 40px;
  }

  .teamCoachesGrid {
    grid-template-columns: repeat(2, 2fr);
  }

  .banner {
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 40px);
  }
}

@media (max-width: 767.98px) {
  .containerSection {
    flex-direction: column;
  }

  .containerText {
    width: fit-content;
    margin: 40px 24px;
  }

  .containerImage {
    width: 100%;
  }

  .programDescriptionSection {
    height: auto;
  }

  .itemsSection {
    flex-direction: column;
    margin: 40px 24px 80px 24px;
  }

  .item {
    width: 100%;
    margin: 16px;
    padding: 8px;
  }
  
  .programCard,
  .programCardLast {
    margin: 24px;
  }

  .titleSection {
    font-size: 36px;
  }

  .subtitleSection,
  .buyButton {
    margin-left: 0;
  }

  .teamCoachesGrid {
    grid-template-columns: auto;
  }

  .banner {
    width: calc(100% - 24px);
  }
}

@media (max-width: 575.98px) {
  .containerSection {
    flex-direction: column;
  }

  .containerText {
    width: fit-content;
    margin: 40px 24px;
  }

  .containerImage {
    width: 100%;
  }

  .programDescriptionSection {
    height: auto;
  }

  .itemsSection {
    flex-direction: column;
    margin: 40px 24px 80px 24px;
  }

  .item {
    width: 100%;
    margin: 16px;
    padding: 8px;
  }

  .programCard,
  .programCardLast {
    margin: 24px;
  }

  .titleSection {
    font-size: 36px;
  }

  .subtitleSection,
  .buyButton {
    margin-left: 0;
  }

  .teamCoachesGrid {
    grid-template-columns: auto;
  }

  .banner {
    width: calc(100% - 24px);
  }
}