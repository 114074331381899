.footer {
    width: 100%;
    display: flex;
    flex-flow: column;
    background-color: var(--primary);
}

.logo-ctr {
    max-width: 1200px;
    padding: 0 2rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
}

.logo-ctr img {
    height: 3rem;
    width: auto;
}

.contact {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 2rem 1rem;
}

.contact a, .contact p {
    color: var(--secondary);
    text-decoration: none;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    font-size: 0.8rem;
}