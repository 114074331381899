.app {
    display: flex;
    flex-direction: column;
    height: 135vh;
    width: 179vh;
    background-color: #005280;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #005280;
    flex-wrap: wrap;
}

.actions {
    display: flex;
    align-items: right;
    gap: 12px;
}

.icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.title {
    margin-left: 20px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding-right: 20px;
}

.container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cono {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 800px;
    margin: 0 auto;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.flecha {
    position: absolute;
    height: 6px; 
    background-color: transparent;
    z-index: 2;
    overflow: visible !important; 
}

.nodo {
    position: absolute;
    padding: 4px 8px;
    border-radius: 12px;
    max-width: 70px;
    min-width: 60px;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    z-index: 3;
}

.ambicioso {
    background-color: #EFCE2A;
    color: #000;
}

.adyacente {
    background-color: #A4CBF4;
    color: #000;
}

.directo {
    background-color: #26D07C;
    color: #000;
}

.divergente {
    background-color: #E592BA;
    color: #000;
}

.puesto {
    background-color: #FFFFFF;
    color: #000;
}

@media screen and (max-width: 768px) {
    .header{
        flex-direction: column;
    }
}
