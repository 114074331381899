@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
  --primary: #005280;
  --primary-light: #3F7DA0;
  --secondary: #E4F6EA;
  --light: #FBFBFC;
  --gray: rgb(216,216,216);
  --error-text: #cc0000;
  --error-bg: #ffcccc;
}

.errorLayout {
  width: 100%;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-family: 'ibm plex sans', sans-serif;
  color: var(--light);
  background-color: var(--primary);
  box-sizing: border-box;
}

.errorLayout h1 {
  font-size: 3.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 1rem;
}

.errorLayout img {
  margin: 2rem 0 1rem;
  height: 3rem;
  width: auto;
}

.errorContent {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0; 
  margin: 0;
  gap: 0.5rem; 
  text-align: center;
  min-height: 10vh;
}

.errorContent p {
  font-size: 1.2rem;
  color: var(--light);
}

.errorContent button {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--secondary);
  color: var(--primary);
  border: none;
  transition: background-color 0.3s ease;
}

.errorContent button:hover {
  background-color: var(--primary-light);
}

.errorForm .btn {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 390px) {
  .errorLayout h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .errorContent {
    width: calc(390px - 4rem);
  }
}
