.escenarios-layout {
  width: calc(100% - 3rem);
  height: calc(100vh - 4rem);
  background-color: var(--dark-green);
  padding: 2rem;
}

.react-flow__node {
  border-radius: 20px !important;
  pointer-events: none !important;
}

.react-flow__handle {
  background: white !important;
  pointer-events: none !important;
}

.node-label .react-flow__handle {
  opacity: 0 !important
}