.file-upload {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: solid 1px var(--input-gray);
    border-radius: 10px;
    color: var(--input-gray);
}

input::file-selector-button {
    background-color: var(--gray);
    color: black;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
}

.uploaded-file-container {
    display: flex;
    align-items: center;
}

.uploaded-file {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    font-weight: bold;
    line-height: 1;
    color: var(--primary);
    background-color: var(--secondary);
    padding: 1rem 0 1rem 1rem;    
    border-radius: 15px 0 0 15px;
}

.uploaded-file svg {
    height: 2rem;
    width: 2rem;
    color: var(--primary-light);
}

.delete-button {
    background-color: var(--secondary);
    border: none;
    cursor: pointer;
    color: #FF4C4C;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.3rem;
    border-radius: 0 15px 15px 0;
    transition: background 0.3s;
}

.delete-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

@media (max-width: 768px) {
    .delete-button {
      padding: 1.6rem; /* Increased padding for mobile */
    }
  }

