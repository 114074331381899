@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
  --primary: #005280;
  --primary-light: #3F7DA0;
  --secondary: #E4F6EA;
  --light: #FBFBFC;
  --gray: rgb(216, 216, 216);
}

.termsLayout {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--primary);
  color: var(--light);
  font-family: 'ibm plex sans', sans-serif;
  align-items: center; /* Center content horizontally */
  justify-content: center;
}

.pageTitle {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  color: var(--light);
  margin-bottom: 2rem;
}

.termsContent {
  max-width: 800px; /* Narrower content */
  margin: 0 auto;
  text-align: left; /* Center text */
  line-height: 1.6;
  font-size: 1.1rem;
}

.section {
  margin-bottom: 1.5rem;
}

.section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--light);
}

.section p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--light);
}

.subsection h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--light);
}

.subsection p {
  font-size: 1rem;
  color: var(--light);
}

.pointsList {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.pointItem {
  margin: 0.5rem 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.backButton {
  background-color: var(--light);
  color: var(--primary);
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.backButton:hover {
  background-color: var(--secondary);
}

@media (max-width: 768px) {
  .termsLayout {
    overflow-x: unset;
    padding: 10px;
    justify-content: flex-start;
  }

  .termsLayout img {
    width: 120px; /* Smaller image size for mobile */
    height: auto;
  }
  
  .pageTitle {
    font-size: 2rem; /* Reduce title size */
  }

  .termsContent {
    max-width: 90%; /* Use most of the screen width */
    text-align: left; /* Change text alignment for better reading on mobile */
    font-size: 1rem;
  }

  .section h2 {
    font-size: 1.5rem; /* Smaller title size */
    margin-top: 15px;
  }

  .subsection h3 {
    font-size: 1.3rem;
  }

  .backButton {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
