.breadcrumbs {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: 0.8rem;
    padding-left: 2rem;
    letter-spacing: 1px;
}

.breadcrumbs a,
.breadcrumbs span {
    color: var(--gray-dark);
    font-weight: 300;
    text-decoration: none;
}

/* Estilo para el módulo */
.breadcrumb-module {
    color: var(--gray-dark);
    font-weight: 300;
}

/* Estilo para la sección actual */
.breadcrumb-current {
    color: var(--gray-dark);
    font-weight: 480;
}

/* Ocultar en pantallas pequeñas */
@media (max-width: 756px) {
    .breadcrumbs {
        visibility: hidden;
        position: absolute;
        opacity: 0;
    }
}
