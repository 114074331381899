
.signupLayout {
  width: 100%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-family: "ibm plex sans", sans-serif;
  color: var(--light);
  background-color: var(--primary);
  box-sizing: border-box;
}

.signupLayout h1 {
  font-size: 3.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 2rem;
}

.signupLayout img {
  margin: 2rem 0 1rem;
  height: 3rem;
  width: auto;
}

.signupForm {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 2rem;
  margin-bottom: 2rem;
  border-bottom: solid 1px var(--primary-light);
  gap: 1rem;
}

.signupForm form {
  width: 100%;
}

.signupForm input {
  font-family: "ibm plex sans", sans-serif;
  margin: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-width: 1px;
  border-color: var(--light);
  border-radius: 10px;
  background-color: var(--primary) !important;
  color: var(--light) !important;
}

.signupForm input[type="checkbox"] {
  margin-right: 8px;
}

.signupLayout ::placeholder {
  color: var(--secondary);
}

.signupForm button {
  font-size: 1.1rem;
}

.signupForm .link {
  margin-right: auto;
}

.signupLayout .link {
  background-color: transparent;
  border: none;
  color: var(--light);
  text-decoration: underline;
}

.signupForm .btn {
  padding: 0.6rem 1rem;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: none; 
  box-shadow: none;
}

.signupForm .btn:disabled {
  background-color: var(--gray);
  color: var(--primary);
  cursor: not-allowed;
  border: none;  
  box-shadow: none;
}

.signupForm .btnFilled {
  background-color: var(--secondary-dark);
  color: var(--primary);
}

.errorMsg {
  padding: 0.5rem 1rem;
  color: var(--error-text);
  background-color: var(--error-bg);
  border-radius: 10px;
  text-align: center;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #005280;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 390px) {
  .signupLayout h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .signupForm {
    width: calc(390px - 4rem);
  }
}
