.MuiBox-root h2 {
    margin-top: 0;
}

.MuiBox-root p {
    margin-bottom: 0;
}

.MuiBox-root a {
    text-decoration: none;
}

.paralelogram {
    width: 100%;
	height: auto;
    padding: 16px 0;
	transform: skew(-20deg);
	background: #275D81;
    text-align: center;
    box-shadow: 10px 10px 0 rgba(255, 255, 255, 1);
}

.paralelogram h2 {
    transform: skew(20deg);
    width: 70%;
    margin: 0 auto;
}

.description {
    font-size: 20px;
    text-align: center;
    margin-top: 24px;
}

.cta {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    background: #275D81;
    padding: 16px 32px;
    border-radius: 8px;
    color: white;
    text-decoration: none;

    margin: 24px auto 0;
    width: fit-content;
}

.cta:hover {
    background: #1A3D54;
}