.vocaLayout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "ibm plex sans", "Roboto", sans-serif;
  gap: 3rem;
  max-width: 1200px;
  margin: 0.3rem;
  padding: 1.5rem;
  width: calc(100% - 3rem);
  min-height: calc(100vh - 4.5rem - 2rem);
}

.loader {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.collapsed {
  width: 3rem;
}

.collapseButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary);
}

.collapsed .collapseButton {
  text-align: center;
}

.col25 {
  line-height: 160%;
  display: flex;
  width: 25%;
  min-width: 200px;
  height: 100%;
  transition: width 0.3s ease;
}

.col75 {
  font-size: 14px;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
}

.col75 h1 {
  padding: 8px 0 8px 0;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: normal;
  letter-spacing: 0.3px;
  font-style: normal;
}

.col75 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Centra las imágenes si es necesario */
}

.form {
  padding-top: 1rem;
}

.col75 p,
.col75 li {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 160%;
  margin-top: 16px;
}

.btnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--light-gray);
  margin-bottom: 0.4rem;
}

/* Media query para dispositivos de 765px o menos */
@media (max-width: 765px) {
  .vocaLayout {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .col25 {
    width: 100%;
    margin-bottom: 1rem; /* Espacio entre col25 y col75 */
  }

  .col75 {
    width: 100%;
  }

  .col75 h1 {
    font-size: 1.5rem;
    padding: 4px 0;
  }

  .col75 p,
  .col75 li {
    font-size: 0.95rem;
  }

  .btnGroup {
    flex-direction: row;
    align-items: stretch;
  }

  .divider {
    width: 100%;
  }
}
