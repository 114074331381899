.btn {
    padding: 0.7rem 1.5rem;
    width: fit-content;
    font-size: 1rem;
    letter-spacing: 0.5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: 300ms;

    cursor: pointer;
}

.sm {
    font-size: 0.8rem;
    padding: 0.25rem 0.8rem;
}

.md {
    font-size: 1rem;
    padding: 0.5rem 1rem;
}

.lg {
    font-size: 1.25rem;
    padding: 0.7rem 1.5rem;
}

.btn svg {
    font-size: 1.25rem;
}

.btn-filled {
    background-color: var(--primary);
    color: var(--light);
    border: none;
    transition: 300ms;
}

.btn-filled:hover {
    background-color: var(--primary-light);
    transition: 300ms;
}

.btn-outline {
    background-color: transparent;
    border: solid 2px var(--primary);
    color: var(--primary);
    transition: 300ms;
}

.btn-outline:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: 300ms;
}

.btn-disabled {
    border: none;
    background-color: var(--gray);
    color: var(--light);
    cursor: not-allowed;
}

.square-btn {
    border-radius: 0;
}

.rounded-btn {
    border-radius: 10px;
}

.pill-btn {
    border-radius: 999px;
}