.testimonial-carousel {
    width: 100%;
    position: relative;
  }
  
  .testimonial {
    background-color: #005280;
    color: white;
    padding: 24px;
    flex: 0 0 33.33%; 
    display: flex;
    height: 100%;
    min-height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-name {
    color: white;
    text-align: right;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal; 
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: right;
    width: 100%;
  }
  
  .testimonial-quote {
    color: white;
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    text-align: left;
  }
  
  .carousel-controls {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  
  .btn-prev,
  .btn-next {
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .btn-prev:hover,
  .btn-next:hover {
    opacity: 0.8;
  }

.carousel-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    gap: 16px;
    transition: transform 0.5s ease; 
    align-self: center;
}