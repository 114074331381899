.plusbutton{
    top: 2.8rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px); /* Aparece desde arriba */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Posición normal */
    }
}

.node {
    animation: fadeIn 0.3s ease-in-out; /* Animación de entrada */
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 500px; 
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        max-height: 500px;
        opacity: 1;
    }
    to {
        max-height: 0;
        opacity: 0;
    }
}

.details-content {
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.details-content.open {
    animation: slideDown 0.3s ease forwards;
}

.details-content.close {
    animation: slideUp 0.3s ease forwards;
}

.animated-li{
    padding-left: 20px;
    flex-wrap: wrap;
}

.container-animated{
    max-width: 20rem;
}

@media screen and (max-width: 768px) {
    .animated-details {
        width: 95%;
        padding: 10px;
    }

    .node {
        flex-direction: column; 
        align-items: center;
        width: 100%; 
    }

    .chip-container {
        width: 100%; 
    }

    .line {
        width: 100%; 
    }

    .dropdown-button {
        width: 100%;
        justify-content: center; 
    }

    .animated-li{
        flex-flow: row;
    }

    .lines{
        visibility: hidden;
    }

    .escenario-add{
        top: -38px;
        right: -4rem;
    }

    .container-animated{
        max-width: 30rem;
    }

    .plusbutton{
        top: 1.9rem;
        left: 5.2rem;
    }
}
