/* Contenedor principal del Bot */
.botContainer {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.imgBot {
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
}

.loadingBubble {
    background-color: #00517F; /* Fondo azul */
    color: #FFFFFF; /* Texto blanco */
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0.2rem;
    width: fit-content;
    display: flex;
    gap: 0.2rem;
}


.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #FFFFFF; /* Blanco */
    border-radius: 50%; /* Forma circular */
    animation: pulse 1.5s infinite;
}

/* Retrasos para cada punto */
.dot:nth-child(1) {
    animation-delay: 0.2s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

/* Animación de pulsación */
@keyframes pulse {
    0%, 100% {
        opacity: 0.2; /* Transparente */
    }
    50% {
        opacity: 1; /* Visible */
    }
}



/* BotBubble.module.css */
.containerBubble {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.img {
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
}


/* ErrorBubble.module.css */
.container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.img {
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
}

.bubbleContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 70%;
}

.actions {
    display: flex;
    gap: 1rem;
}

.actionButton {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.9rem;
}

.retry {
    color: var(--dark-og-1);
}

.retry:hover {
    color: var(--primary);
}

.delete {
    color: var(--status-danger-600);
}

.delete:hover {
    color: var(--status-danger-400);
}


/* UserBubble.module.css */
.bubble {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border-bottom-right-radius: 0.2rem;
    font-size: 0.9rem;
    max-width: 70%;
    background-color: #00517F !important;
    color: #FFFFFF !important;
    align-self: flex-end;
}