.chatContainer {
  position: fixed;
  right: 75px;
  bottom: 60px;
  font-family: sans-serif;
  border-radius: 0.5rem;
  z-index: 100001;
  transition: all 0.5s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.maximizedChatContainer {
  width: 90%;
  height: calc(100% - 50px - 50px - 50px - 1rem);
}

.standardChatContainer {
  width: 28rem;
  max-width: 90%;
}

/* ChatContent.module.css */
.chatContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #EBEBEA;
  overflow-y: auto;
  gap: 1rem;
  min-height: 300px;
  scrollbar-width: none;
}

.maximizedChatContent {
  max-height: -webkit-fill-available;
  height: calc(100% - 14px - 1.5rem - 14px - 2rem - 35px);
}

.standardChatContent {
  max-height: 400px;
}

/* ChatHeader.module.css */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f9f9f9;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid #d1d1d1;
}

.title {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #555;
  transition: color 0.3s ease;
}

.iconButton:hover .icon {
  color: #888;
}

/* ChatTextbox.module.css */
.textboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f4f4f4;
  gap: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  flex-direction: row;
}

.input {
  flex-grow: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #e6e6e6;
}

.sendButton {
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .chatContainer {
    width: 18rem;
    height: 38rem; 
    right: 45px;
    bottom: 40px;
  }

}