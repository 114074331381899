.aprendizaje-layout {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 2rem;
  max-width: 1300px;
  margin: auto;
  padding: 2rem;
  width: calc(100% - 4rem);
  overflow-x: hidden;
  min-height: calc(100vh - 4.5rem - 2rem);
}

.error-container {
  background-color: #fff;
  color: #01527F;
  padding: 16px;
  justify-content: center;
  margin: 16px 0;
  text-align: center;
  font-family: Arial, sans-serif;
}

.error-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.error-details {
  font-size: 14px;
  margin: 0;
}

.cono-scroll-wrapper {
  width: 85vw;
  height: 85vh;
  max-width: 900px;
  max-height: 600px;
  overflow: auto; /* 🔹 Activa scroll */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #005280;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.cono-container {
  width: 1400px; /* Más ancho para permitir scroll */
  height: 1000px; /* Más alto para permitir scroll */
  min-width: 1400px;
  min-height: 1000px;
  max-width: 1400px;
  max-height: 1000px;
  overflow: hidden;
  position: relative;
  transform: translate(450px, 230px);

}

@media (max-width: 1024px) {
  .cono-scroll-wrapper {
    width: 90vw;
    height: 85vh;
  }
}

@media (max-width: 480px) {
  .cono-scroll-wrapper {
    overflow-x: auto;
  }

  .cono-container {
    width: 1200px;
    height: 800px;
  }
}

@media (min-width: 768px) {
  .aprendizaje-layout {
    flex-flow: row;
    gap: 6rem;
    overflow-x: scroll;
  }

  .cono-scroll-wrapper {
    width: 95vw;
    height: 90vh;
  }

  .cono-container {
    width: 1200px;
    height: 800px;
  }
}