@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.homeLayout {
    font-family: 'ibm plex sans', 'Roboto', sans-serif;
}

.homeBanner {
    background-color: var(--secondary);
    width: 100%;
    padding: 4rem 0;
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.homeBanner > * {
    padding: 0 2rem;
    max-width: 67ch;
    margin: 0 auto;
    color: var(--primary);
    text-align: center;
    line-height: 1.5;
}

.homeBanner h1 {
    font-weight: 500;
}

.newsletterContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 16rem;
}

.newsletterText {
    width: calc(33% - 8rem);
    height: calc(100% - 8rem);
    padding: 4rem;
    background-color: var(--primary);
    color: var(--secondary);
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.newsletterText h4, .newsletterForm h4 {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
}

.newsletterText p, .newsletterForm p {
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.25;
}

.newsletterText a {
    color: var(--secondary);
    text-decoration: none;
    font-weight: 400;
}

.newsletterCta {
    background-color: var(--secondary);
    width: calc(67% - 4rem);
    padding: 2rem;
    height: calc(100% - 4rem);
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    color: var(--primary);
}

.newsletterCta img {
    max-height: 80%;
    width: auto;
}

.newsletterForm {
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.newsletterForm > div {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
}

.newsletterForm button {
    margin-left: auto;
}

.newsletterForm input {
    background-color: transparent;
    border: solid 1px var(--primary);
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    color: var(--primary);
}

.homeCardContainer {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-flow: column;
    padding: 6rem 2rem;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

@media (max-width: 1250px) {
    .newsletterText {
        width: calc(33% - 4rem);
        height: calc(100% - 8rem);
        padding: 4rem 2rem;
    }
}

@media (max-width: 1080px) {
    .newsletterText {
        width: calc(40% - 4rem);
    }

    .newsletterCta {
        width: calc(60% - 4rem);
        gap: 2rem;
    }
    
    .newsletterCta img {
        max-height: 50%;
    }
}

@media (max-width: 880px) {
    .homeCardContainer {
        padding: 4rem 2rem;
    }
    
    .newsletterContainer {
        flex-flow: column;
        height: auto;
    }

    .newsletterText {
        width: calc(100% - 4rem);
    }

    .newsletterCta {
        width: calc(100% - 4rem);
        gap: 4rem;
    }

    .newsletterCta img {
        max-height: 6rem;
    }
}

@media (max-width: 560px) {
    .homeBanner h1 {
        font-size: 1.5rem;
    }

    .homeBanner p {
        font-size: 0.9rem;
    }

    .newsletterCta {
        gap: 2rem;
    }

    .newsletterCta img {
        max-height: 4rem;
    }
}

@media (max-width: 420px) {
    .newsletterText p, .newsletterForm p {
        font-size: 0.8rem;
    }

    .newsletterCta img {
        max-height: 4rem;
    }
}

@media (max-width: 370px) {
    .homeBanner {
        padding: 2rem 0;
    }
    
    .homeBanner h1 {
        font-size: 1.5rem;
        line-height: 1.2;
    }
    
    .homeBanner p {
        font-size: 0.8rem;
    }
    
    .newsletterCta {
        flex-flow: column;
        height: auto;
    }

    .newsletterCta img {
        height: 4rem;
    }

    .newsletterForm h4 {
        text-align: center;
    }
}
