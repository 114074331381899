.checkbox-columns {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
}

form {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.questionTitle {
    margin-bottom: 8px;
}

.error {
    color: red;
    font-size: 1rem;
    margin: 1rem 0;
}

legend{
    font-size: 14px;
}