.tendencias-layout.compact-mode {
  width: 100%;
  max-width: 100%;
  font-size: 0.9rem;
  padding: 1rem;
  gap: 1rem;
}

.tendencias-layout.compact-mode h2 {
  font-size: 1rem;
}

.tendencias-layout.compact-mode .tendencias-avatar {
  height: 3rem;
  width: 3rem;
}

.tendencias-layout.compact-mode .tendencias-chart > div {
  width: 15%; /* Reduce el ancho de las columnas */
}

.tendencias-layout.compact-mode .trend-bar {
  height: 1rem; /* Reduce la altura de las barras */
  width: 8rem;
}

.tendencias-layout.compact-mode .tendencias-text {
  padding: 1rem;
  width: 100%;
}

.tendencias-layout.compact-mode .chart-line {
  width: 0.5rem;
}

.tendencias-layout.compact-mode .tendencias-footer {
  width: 80%;
}

body {
  box-sizing: border-box;
  background-color: white;
}

.tendencias-layout {
  max-width: 80%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: "ibm plex sans", "Roboto", sans-serif;
  background-color: white;
  line-height: 1.25;
  gap: 2rem;
}

.tendencias-layout h2,
.tendencias-layout h3,
.tendencias-layout h4,
.tendencias-layout p {
  margin: 0;
}

.tendencias-layout h2 {
  color: var(--green);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.tendencias-columns {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
}

.tendencias-profile,
.tendencias-styles {
  width: calc(50% - 1rem);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.tendencias-profile {
  gap: 0.5rem;
}

.tendencias-profile h4 {
  color: var(--green);
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-style: italic;
  font-size: 0.75rem;
}

.tendencias-profile h3 {
  font-weight: 600;
  font-size: 1.125rem;
}

.tendencias-avatar {
  height: 4.5rem;
  width: 4.5rem;
}

.tendencias-chart {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.tendencias-chart > div {
  width: 20%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.tendencias-chart span {
  font-style: italic;
  font-size: 0.75rem;
}

.current-label {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
}

.chart-index {
  width: 2rem;
  height: 2rem;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-top: 0.25rem;
}

.chart-below .chart-index {
  background-color: var(--gray);
  color: var(--gray-dark);
}

.chart-equal .chart-index {
  background-color: var(--input-gray);
  color: white;
}

.chart-above .chart-index {
  background-color: var(--green);
  color: white;
}

.chart-below span {
  color: var(--gray-dark);
}

.chart-equal,
.chart-above {
  position: relative;
}

.current-arrow {
  position: absolute;
  top: -1rem;
  right: 3.5ch;
  width: 2rem;
  height: 2rem;
}

.above-arrow {
  position: absolute;
  top: -1.75rem;
  right: -0.5rem;
  width: 2rem;
  height: 2rem;
}

.chart-equal span {
  color: var(--input-gray);
  font-weight: 700;
  font-size: 1rem;
}

.chart-above span {
  color: var(--green);
  font-weight: 700;
  font-size: 1rem;
}

.chart-line {
  width: 1rem;
  border-left: dashed 2px var(--gray);
}

.chart-dot {
  width: 1rem;
  height: 1rem;
  background-color: var(--gray);
  margin-right: calc(0.5rem + 1px);
  border-radius: 999px;
}

.line-1 {
  height: 2rem;
}

.line-2 {
  height: 4rem;
}

.line-3 {
  height: 6rem;
}

.line-4 {
  height: 8rem;
}

.line-5 {
  height: 10rem;
}

.line-6 {
  height: 12rem;
}

.tendencias-styles {
  gap: 2.5rem;
  justify-content: flex-end;
}

.tendencias-styles h3 {
  font-weight: 600;
}

.tendencias-styles h4 {
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;
}

.tendencias-info {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.tendencias-text {
  width: calc(50% - 4rem - 1px);
  padding: 2rem 2rem 0;
}

.tendencias-text:nth-of-type(1) {
  border-right: solid 1px;
  border-image: linear-gradient(
      to bottom,
      var(--gray) 100%,
      rgba(0, 0, 0, 0) 100%
    )
    1 100%;
}

.tendencias-text:nth-of-type(3) {
  border-right: solid 1px;
  border-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 17.5%,
      var(--gray) 17.5%
    )
    1 100%;
}

.tendencias-text:nth-of-type(3) h4,
.tendencias-text:nth-of-type(4) h4 {
  border-top: solid 1px var(--gray);
  padding-top: 2rem;
}

/*

.tendencias-text:nth-of-type(2) {
    border-bottom: solid 1px var(--gray);
    border-left: solid 1px var(--gray);
}

.tendencias-text:nth-of-type(3) {
    border-top: solid 1px var(--gray);
    border-right: solid 1px var(--gray);
}

*/

.tendencias-text h4 {
  color: var(--green);
  font-style: italic;
  font-weight: 600;
  margin-bottom: 1rem;
}

.tendencias-text ol {
  margin: 0;
}

.tendencias-text li {
  margin-bottom: 1rem;
}

.trend-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.trend-data {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  flex: 1 1;
}

.trend-data p {
  width: 10rem;
}

.trend-data p:first-of-type {
  text-align: right;
}

.low-label {
  font-style: italic;
  font-weight: 300;
  color: var(--gray-dark);
}

.high-label {
  font-style: italic;
  font-weight: 600;
  text-decoration: underline;
  color: var(--green);
}

.trend-bar {
  display: flex;
  background-color: var(--gray);
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 10rem;
  border-radius: 999px;
  overflow: hidden;
}

.trend-bar > div {
  height: 1.25rem;
}

.bar-wrapper {
  width: 50%;
  display: flex;
}

.bar-start {
  margin-left: auto;
  background-color: var(--green);
  height: 1.25rem;
}

.bar-end {
  margin-right: auto;
  background-color: var(--green);
  height: 1.25rem;
}

@media (max-width: 820px) {
  .tendencias-layout {
    max-width: 55%;
  }
  .tendencias-columns {
    flex-flow: column;
    gap: 4rem;
  }

  .tendencias-profile,
  .tendencias-styles {
    width: calc(80% - 1rem);
    margin: auto;
  }

  .tendencias-chart {
    padding-right: 10%;
  }

  .tendencias-info {
    padding: 0;
  }
}

@media (max-width: 600px) {
  .tendencias-columns {
    flex-flow: column;
    gap: 1rem;
    align-items: stretch;
  }

  .tendencias-profile,
  .tendencias-styles {
    width: 100%;
    margin: 0;
  }

  .chart-above span {
    font-weight: 500;
  }

  .above-arrow {
    padding-bottom: 0.8em;
    padding-top: 0.4em;
  }

  .current-arrow {
    padding-bottom: 0.8em;
  }

  .tendencias-chart {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .tendencias-info {
    flex-direction: column;
    gap: 1rem;
    padding: 0;
  }

  .tendencias-text {
    width: 85%;
    padding: 1rem;
    border: none;
  }

  .tendencias-footer {
    width: 100%;
    margin-top: 2rem;
  }

  .error-container {
    background-color: #fff;
    color: #01527f;
    padding: 16px;
    justify-content: center;
    margin: 16px 0;
    text-align: center;
    font-family: Arial, sans-serif;
  }

  .error-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .error-details {
    font-size: 14px;
    margin: 0;
  }
}

@media (max-width: 475px) {
  .tendencias-layout {
    max-width: 50%;
  }
}
