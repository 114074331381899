.card {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 18rem;
    box-shadow: 10px 10px 21px 0px rgba(0,0,0,0.14);
-webkit-box-shadow: 10px 10px 21px 0px rgba(0,0,0,0.14);
-moz-box-shadow: 10px 10px 21px 0px rgba(0,0,0,0.14);
}

.card-cover {
    width: 60%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.card-info {
    padding: 2rem;
    width: calc(40% - 2rem);
    background-color: var(--secondary);
    color: var(--primary);
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.card-info h3 {
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0;
}

.card-info p {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
}

.card-info span {
    font-size: 0.8rem;
    font-weight: 300;
}

.card-info button,
.card-info a {
    margin-left: auto;
    margin-top: auto;
}

.card-btn {
    padding: 0.7rem 1.5rem;
    width: fit-content;
    font-size: 1rem;
    letter-spacing: 0.5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: 300ms;
    text-decoration: none;
    cursor: pointer;
}

.card-btn {
    background-color: transparent;
    border: solid 2px var(--primary);
    color: var(--primary);
    transition: 300ms;
    border-radius: 10px;
    
    font-size: 0.8rem;
    padding: 0.25rem 0.8rem;
}

.card-btn:hover {
    background-color: var(--primary);
    color: var(--secondary);
    transition: 300ms;
}

@media (max-width: 620px) {
    .card-cover {
        width: 50%;
    }

    .card-info {
        width: calc(50% - 4rem);
    }
}

@media (max-width: 560px) {
    .card-cover {
        width: 40%;
    }

    .card-info {
        width: calc(60% - 4rem);
    }
}

@media (max-width: 470px) {
    .card {
        flex-flow: column;
        height: fit-content;
    }
    
    .card-cover {
        width: 100%;
        height: 8rem;
    }

    .card-info {
        width: calc(100% - 4rem);
    }
}