.profileContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Encabezado de Perfil */
.profileHeader {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  gap: 15px;
  flex-wrap: wrap;
  /* Permite que los elementos se ajusten en pantallas pequeñas */
}

.profileImage {
  width: 100px;
  height: 100px;
  background-color: #9FDADC;
  color: #01527f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.profileDetails h2 {
  margin: 0;
  font-size: 1.6rem;
}

.profileDetails p {
  margin: 5px 0 0;
  color: #777;
}

.profileInitials {
  font-size: 4rem;
  font-weight: 400;
}

/* Pestañas */
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 6px;
  border-bottom: 1px solid #ddd;
}

.tabButton {
  padding: 8px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s;
  min-width: 120px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #777;
}

.tabButton:hover {
  background: #f0f0f0;
}

.activeTab {
  color: #005280;
  font-weight: 600;
  border-bottom: 2px solid #005280;
  background: transparent;
}


/* Contenido de las Pestañas */
.tabContent {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
}

.learningProject {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 100%; 
  overflow: hidden; 
}


/* Media Queries para Responsividad */
@media screen and (max-width: 768px) {
  .profileHeader {
    flex-direction: column;
    /* Los elementos se apilan en pantallas pequeñas */
    text-align: center;
  }

  .tabs {
    flex-direction: column;
    /* Las pestañas se apilan en pantallas pequeñas */
    gap: 5px;
  }

  .tabButton {
    min-width: 100%;
    /* Ocupa todo el ancho disponible */
  }

  .tabContent {
    padding: 10px;
  }

  .profileInitials {
    font-size: 15px;
  }

}

@media screen and (max-width: 480px) {
  .profileImage {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .profileDetails h2 {
    font-size: 1.1rem;
  }

  .tabButton {
    font-size: 14px;
    padding: 8px 15px;
  }
}