.EmptySidebar {
  width: 100%;
  padding: 1rem 1.5rem 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  background-color: var(--secondary);
  font-family: "ibm plex sans", "Roboto", sans-serif;
  box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.14);
}

.EmptySidebar p {
  margin: 0;
}

.EmptySidebar-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.EmptySidebar-title p {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--primary);
}

.EmptySidebar-buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.step {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  cursor: pointer;
  line-height: 160%;
  font-size: 14px;
}

.step-complete {
  cursor: pointer;
}

.step-complete:hover {
  text-decoration: underline;
}

.current {
  font-weight: 600;
}

.index {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  width: 1.9rem;
  height: 1.9rem;
  font-size: 14px;
}

.index-complete {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.index-pending {
  background-color: var(--input-gray);
  color: var(--light);
}

.index-pending p {
  color: var(--light-gray);
}

.step-pending p {
  width: calc(100% - 3rem);
  color: var(--gray-subtitle);
}

.step-complete p {
  width: calc(100% - 3rem);
  color: var(--dark);
}

@media (max-width: 472px) {
  .EmptySidebar{
    width: 89%;
    padding: 1rem 1.5rem 1rem;
  }

}
