@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.landing {
    font-family: 'Poppins', 'Roboto', sans-serif;
}

.landingHero {
    display: grid;
    grid-template-columns: 100%;
}

.landingHeroText {
    display: flex;
    flex-direction: column;
    background: var(--secondary);
    padding: 1rem;
    order: 2;
}

.landingHeroText h1 {
    color: var(--primary);
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    margin: 0 0 1rem;
}

.landingHeroText p {
    color: var(--primary);
    margin: 0 0 1rem;
}

.landingHeroImage {
    order: 1;
    width: 100%;
    height: 100%;
}

.landingHeroImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.landingDescription {
    padding: 1rem;
    background: var(--primary);
    color: var(--light);
    text-align: center;
}

.landingSeparator {
    padding: 1rem;
    background: var(--secondary);
    color: var(--primary);
    text-align: center;
}

.landingTools {
    display: grid;
    grid-template-columns: 100%;
}

.landingTool {
    text-align: center;
    padding: 4rem;
}

.landingTool h4 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    margin: 0 0 1rem;
}

.landingTool p {
    text-align: center;
}

.landingTool img {
    margin: 0 auto 1rem;
}

.landingTool:nth-child(1) {
    background: #A0DBDC;
    color: var(--primary);
}

.landingTool:nth-child(2) {
    background: #3F7DA0;
    color: var(--light);
}

.landingTool:nth-child(3) {
    background: #005280;
    color: var(--light);
}

.landingCta {
    display: grid;
    flex-direction: column;
    align-items: left;
    padding: 2rem;
    background-image: url('./cta_bg.png');
    background-size: 180px;
    background-repeat: no-repeat;
    background-position: right;
}

.landingCtaText {
    color: var(--primary);
    font-family: Poppins;
    text-align: left;
}

.landingCtaText p {
    font-size: 16px;
    font-weight: 500;
}

.landingCtaText h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 1rem 0;
}

.landingCards {
    background: var(--primary);
    padding: 1rem;
}

.landingCards h4 {
    color: #E4F6EA;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
}

.landingCardWrapper {
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
}

.landingCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--secondary);
    padding: 1rem;
}

.landingCard p {
    flex: 1;
    color: var(--primary);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
}

.newsletterForm {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .landingHero {
        grid-template-columns: 50% 50%;
    }

    .landingHeroText {
        padding: 4rem 8rem;
        order: 1;
    }
    
    .landingHeroImage {
        order: 2;
    }

    .landingDescription {
        padding: 4rem 8rem;
    }

    .landingSeparator {
        padding: 2rem 8rem;
    }

    .landingTools {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }

    .landingCta {
        display: grid;
        grid-template-columns: 70% 30%;
        align-items: center;
        text-align: center;
        background-image: url('./cta_bg.png');
        background-size: 180px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .landingCtaText {
        padding: 4rem 8rem;
    }

    .landingCtaText p {
        color: var(--primary);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        line-height: normal;
        letter-spacing: 0.24px;
    }

    .landingCtaText h4 {
        color: var(--primary);
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        margin: 0 0 1rem;
    }

    .landingCtaButton {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4rem 8rem 4rem 0;
    }

    .landingCards {
        padding: 4rem 8rem;
    }

    .landingCardWrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
    }
}
