.sidebarContainer {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: calc(100vh - 50px); 
  overflow: hidden; 
  box-sizing: border-box; 
  height: calc(77vh - 50px);
}

.addGoal {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  flex-shrink: 0; 
  width: 100%; 
  box-sizing: border-box; 
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buttonMain{
  margin: 0 0 10px;
  color: #01527F;
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box; 
  font-size: 12px;
  font-weight: 400;
}

.addButton {
  width: 100%;
  padding: 10px;
  background: #004DFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}

.addButton:hover {
  background: #003d82;
}

.goalsList {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.goalItem {
  background: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.goalCategory {
  display: inline-block;
  font-size: 8px;
  padding: 4px;
  color: #141619;
  font-weight: 400;
}

.goalItem h4 {
  margin: 8px;
  font-size: 12px;
  font-weight: 400;
}

.goalItem p {
  margin: 8px;
  font-size: 12px;
  font-weight: 400;
}

.addGoalToggle {
  color: #0056b3;
  background: transparent;
  border: none;
  font-size: 12px; 
  font-weight: 400; 
  text-align: left;
  padding: 10px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in
}

.dateIcon {
  background: transparent;
  border: none;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px; 
  padding: 0;
}

.deleteButton {
  background: transparent;
  color: #141619;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  padding: 8px; 
  margin-top: 12px;
  border: 1px solid #ddd;
}

.deleteButton:hover {
  color: #ff4c4c;
}

.deleteContainer{
  justify-items: flex-end;
}

.dateAndDelete {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.dateIcon {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #555;
}

.dateIcon svg {
  padding-right: 8px;
}

.addGoalToggle:hover {
  color: #003d82; 
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.completeButton {
  display: flex;
  align-items: center;
  gap: 4px;
  background: transparent;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  color: #333;
  width: 12rem;
}

.completeButton:hover {
  border-color: #ccc;
  color: #000;
}

.category{
  padding: 8px 8px 8px 8px;
}

.selectedGoal {
  border: 2px solid #004DFF;
  border-radius: 5px;
}

.expandedSection {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}