.layout {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 20px;
    font-family: 'ibm plex sans', 'Roboto', sans-serif;
    padding: 24px 72px;
}

.button {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    background-color: var(--primary);
    color: var(--light);
    cursor: pointer;

    margin-bottom: 24px;
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    pointer-events: none; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: all;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 9999;  
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db; 
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.successMsg {
    font-family: 'ibm plex sans', sans-serif;
    color: var(--primary);  
    background-color: var(--light);   
    font-size: 0.9rem;
    margin-top: 0.5rem;
    padding: 0.5rem; 
    border-radius: 10px; 
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.successContent button {
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--light);
    border: none;
    transition: background-color 0.3s ease;
}

.successContent button:hover {
    background-color: var(--primary-light);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .layout {
        grid-template-columns: 100%;
    }

    .left {
        order: 2;
    }
}