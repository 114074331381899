table {
  border-collapse: collapse;
}

th {
  font-weight: normal;
  padding: 1rem;
}

tbody th {
  text-align: left;
}

tbody td,
tbody th {
  border: solid 1px var(--gray);
  padding: 1rem;
}

tbody th {
  width: 20ch;
  font-weight: normal;
}

td .MuiFormControl-root {
    width: 100%;
}

td input {
    border: none;
    width: 100%;
    background-color: transparent;
}