.calendarContainer {
  display: flex;
  flex-direction: column; 
  width: 70%; 
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.calendarResponsive {
  width: 100%; 
  min-height: 400px; 
  height: calc(100vh - 150px); 
  max-height: 80vh; 
  margin: 0 auto; 
}

@media screen and (max-width: 768px) {
  .calendarResponsive {
    min-height: 300px; 
    height: calc(100vh - 200px); 
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .calendarContainer {
    width: 100%; 
    padding: 10px; 
  }

  .calendarResponsive {
    min-height: 350px;
    height: auto; 
    padding: 10px;
    width: 100%; 
  }

  .rbc-event {
    font-size: 12px; 
    padding: 4px; 
  }

  .rbc-day-bg {
    min-height: 50px; 
  }
}
