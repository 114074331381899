.container {
    padding: 1.4rem;
    background-color: #005280;
    border-radius: 12px;
    width: 100%; 
    max-width: 30rem; 
    box-sizing: border-box; 
    overflow-x: hidden; 
    margin: 0 auto; 
}

.innerContainer {
    position: relative;
    margin-top: 20px;
    max-height: 100rem;
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    align-content: center;
    z-index: 5;
}

@media (min-width: 768px) {
    .container {
        padding: 6rem;
        border-radius: 16px;
        width: 100%;
        min-width: 55rem;
        margin: 0 auto; 
    }

    .innerContainer {
        max-height: 120rem;
    }
}

