/* ToggleButton.module.css */
.button {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    transition: all 0.033s ease-in-out;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
  }
  
  .button:hover {
    opacity: 0.75;
  }
  
  .small {
    height: 30px;
    width: 50px;
    right: 20px;
    bottom: 50px;
  }
  
  .large {
    height: 45px;
    width: 45px;
    right: 20px;
    bottom: 75px;
  }
  
  .img {
    border-radius: 50%;
    width: 180%;
    height: 180%;
  }
  