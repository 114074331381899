.loginLayout {
    max-width: 390px;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-family: 'ibm plex sans', sans-serif;
    color: var(--primary);
}

.loginLayout h1 {
    font-size: 3.25rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    margin: 0 0 2rem;
}

.loginLayout img {
    margin: 2rem 0 1rem;
    height: 3rem;
    width: auto;
}

.loginForm {
    width: calc(100% - 4rem);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem 2rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px var(--primary);
    gap: 1rem;
}

.loginForm form {
    width: 100%;
}

.loginForm input {
    font-family: 'ibm plex sans', sans-serif;
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    border-width: 1px;
    border-color: var(--primary);
    border-radius: 10px;
    background-color: var(--light);
}

.loginForm h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.passwordMsg {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 1rem 0;
}

.loginLayout ::placeholder {
    color: var(--primary-light);
}

.loginForm button {
    font-size: 1.1rem;
}

.link {
    background-color: transparent;
    border: none;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
    font-family: 'ibm plex sans', sans-serif;
}

.loginForm .btn {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.errorMsg {
    padding: 0.5rem 1rem;
    color: var(--error-text);
    background-color: var(--error-bg);
    border-radius: 10px;
    text-align: center;
    margin-top: 12px;
}

.successMsg {
    padding: 0.5rem 1rem;
    color: var(--success-text);
    background-color: var(--success-bg);
    border-radius: 10px;
    text-align: center;
}

@media (max-width: 390px) {
    .loginLayout h1 {
        font-size: 2.5rem;
    }
}
