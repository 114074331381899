.proyectoLayout {
    display: flex;
    flex-flow: column;
    font-family: 'ibm plex sans', 'Roboto', sans-serif;
    background-color: var(--bright-green);
    line-height: 1.25;
  }
  
  .proyectoHeader {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--dark-green);
    padding: 2rem;
    color: var(--light-green);
    gap: 1rem; 
  }

  .proyectoLogo {
    position: absolute;
    height: 4rem;
    width: auto;
    left: 3rem;
  }
  
  .proyectoHeader p {
    font-size: 1.5rem;
    font-weight: 500;
    flex: 1;
    text-align: center; 
  }

.proyectoBanner {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    padding: 4rem 2rem 0;
    color: var(--dark-green);
}

.proyectoBanner img {
    width: 100%;
    height: auto;
    text-align: center;
}

.proyectoBanner h3 {
    margin: 0 auto;
    max-width: 800px;
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.proyectoBanner p {
    margin: 0 auto 3rem;
    max-width: 800px;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 500;
}

.proyectoColumns {
    display: flex;
    flex-flow: row;
}

.strengths {
    background-color: var(--dark-green);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    width: calc(50% - 4rem);
}

.strengths img {
    height: 6rem;
    width: auto;
    margin-bottom: 2rem;
}

.strengths h4 {
    color: var(--blue);
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}
.strengths ol {
    color: white;
    max-width: 400px;
    width: 100%;
    margin: 0;
}

.strengths li {
    margin-top: 3rem;
    font-weight: 500;
}

.strengths span {
    color: var(--blue);
    font-style: italic;
}

.opportunities {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4rem;
    width: calc(50% - 8rem);
    background-color: var(--light-blue);
}

.opportunities h4 {
    color: var(--dark-green);
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 1.5rem;
}

.opportunity {
    display: flex;
    flex-flow: column;
    max-width: 600px;
    width: 100%;
}

.oppHeader {
    background-color: var(--green);
    color: white;
    border-radius: 24px 0 0 0;
    padding: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
}

.opportunity span {
    background-color: white;
    padding: 1.5rem 1.5rem 0;
    margin: 0 auto;
    font-size: 1.25rem;
    font-weight: 500;
    font-style: italic;
    color: var(--green);
    width: calc(100% - 3rem);
}

.opportunity p {
    background-color: white;
    padding: 0 1.5rem 1.5rem;
    margin: 0 auto;
    font-size: 1.25rem;
    font-weight: 500;
    color: black;
    width: calc(100% - 3rem);
}

@media (max-width: 820px) {
    .proyectoColumns {
        flex-flow: column;
    }

    .strengths {
        width: calc(100% - 4rem);
    }

    .opportunities {
        width: calc(100% - 8rem);
    }

    .opportunity {
        max-width: 100%;
    }
}

@media (max-width: 660px) {

    .proyectoHeader {
        justify-content: flex-start;
        gap: 2rem;
    }
    .proyectoLogo {
        position: relative;
        left: auto;
    }
}

@media (max-width: 500px) {
    .proyectoHeader {
        gap: 1rem;
    }

    .proyectoLogo {
        height: 2.5rem;
    }

    .proyectoHeader p {
        margin: 0;
        font-size: 1.25rem;
    }

    .proyectoBanner h3 {
        font-size: 1.5rem;
    }

    .proyectoBanner img {
        width: calc(100% + 4rem);
    }

    .proyectoBanner p {
        margin-bottom: 2rem;
    }

    .proyectoBanner p, .opportunity span, .opportunity p {
        font-size: 1rem;
    }

    .oppHeader {
        font-size: 1.25rem;
        padding: 1rem;
    }

    .opportunity span {
        padding: 1rem 1rem 0;
        width: calc(100% - 2rem);
    }

    .opportunity p {
        padding: 0 1rem 1rem;
        width: calc(100% - 2rem);
    }
}

@media (max-width: 400px) {
    .proyectoHeader {
        padding: 1rem;
    }

    .opportunities {
        padding: 4rem 2rem;
        width: calc(100% - 4rem);
    }
}

@media (max-width: 365px) {
    .proyectoLogo {
        height: 2rem;
    }

    .proyectoHeader p {
        font-size: 1.1rem;
    }

    .proyectoBanner h3 {
        font-size: 1.25rem;
    }

    .strengths h4 {
        text-align: center;
        font-size: 1.25rem;
    }
}

@media (max-width: 325px) {

    .proyectoHeader p {
        font-size: 1rem;
    }

    .opportunities h4 {
        font-size: 1.25rem;
    }
}