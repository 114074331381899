.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.phoneInput {
    display: flex;
    gap: 1rem;
}

.phoneInput .phoneCode {
    width: 30%;
}

.phoneInput .phoneNumber {
    width: calc(70% - 1rem);
}

.checkboxInput {
    display: flex;
    gap: 1rem;
}

.link {
    background-color: transparent;
    border: none;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.button {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    background-color: var(--primary);
    color: var(--light);
    cursor: pointer;

    margin-bottom: 24px;
}

.button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.6; 
}

@media (min-width: 768px) {
    .row {
        flex-direction: row;
    }

    .row > * {
        width: 50%;
    }
}